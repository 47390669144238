import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    if (__subscription().isTrialS3) {
        log("❌ Trial S3");
        return navigateTo("/");
    }

    log("✅ Not trial S3");
});
